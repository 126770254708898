<template>
  <base-section id="theme-features">
    <base-section-heading :title="$t('features_headline')">
      {{ $t('features_text') }}
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features[$i18n.locale]"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature.text }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      features: {
        en: [
          {
            title: 'Effective supplier assessment',
            icon: 'mdi-magnify',
            text: 'Assessment of sustainability risks based on international conventions and declarations that cover all sustainability areas: the environment, labor rights, human rights, anti-corruption and governance.',
          },
          {
            title: 'Risk awareness',
            icon: 'mdi-traffic-light',
            text: 'Risk analyses, checks and supplier self-assessments for ongoing evaluation, onboarding and even procurement processes.',
          },
          {
            title: 'Adaptation to your business',
            icon: 'mdi-format-align-center',
            text: 'Questionnaires and assessments adapted to your business needs and aligned with your Supplier Code of Conduct, which assures a systemised and objective approach. All questionnaires are constructed together with leading experts from our sister company, Ethos International.',
          },
          {
            title: 'Clear overview',
            icon: 'mdi-view-dashboard',
            text: 'All assessment and analysis results are presented in clear graphics and a three-grade scale to easily identify high-risk suppliers. The results are available both on an aggregated level and for each supplier in detail.',
          },
        ],
        sv: [
          {
            title: 'Effektiv leverantörsutvärdering',
            icon: 'mdi-magnify',
            text: 'Bedömning av hållbarhetsrisker baserade på internationella konventioner och deklarationer som täcker alla hållbarhetsområden: miljö, arbetsrätt, mänskliga rättigheter, antikorruption och styrning',
          },
          {
            title: 'Medvetenhet om risker',
            icon: 'mdi-traffic-light',
            text: 'Riskanalyser, kontroller och självskattningar av leverantörer vid löpande utvärdering eller nya affärsrelationer – även vid upphandlingar.',
          },
          {
            title: 'Anpassning till din verksamhet',
            icon: 'mdi-format-align-center',
            text: 'Frågeformulären anpassas till din bransch, dina behov och företagets Supplier Code of Conduct. Det innebär en systematiserad och objektiv utvärdering. Frågorna utformas i samarbete med experter hos vårt systerföretag Ethos International.',
          },
          {
            title: 'En bra översikt',
            icon: 'mdi-view-dashboard',
            text: 'Resultat och analyser presenteras i lättöverskådlig och tydlig grafik och leverantörerna bedöms enligt en tregradig skala som enkelt identifierar högriskleverantörer. Evaluate ger en tydlig översikt av data, både på en aggregerad nivå och för varje leverantör.',
          },
        ],
      }
    }),
  }
</script>
